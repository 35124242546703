/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

 /*  ::-webkit-scrollbar-thumb {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #9fd1f9;
  } //Removed for scrollbar visibiility */


  ::-webkit-scrollbar-thumb {
    background: #9fd1f9;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #65b1ef;
  }
  
/* @media screen and (max-width: 1166px) {
    .menu-item {
        font-size: 10px !important;
        margin-left: 10px !important;
        padding-bottom: 22px !important;
    }
} */
@media screen and (max-width: 991px) {

    
    .makeStyles-headerImg-9 {
        display: none !important;
    }
    
}


.hide-with-drawer {
    display: none !important;
}
.Main-Footer {
  padding: 1% 5%;
  text-align: center;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.Main-Footer img {
  width: 6%;
}
.Main-Footer ul {
  padding: 0;
  margin: 0;
  margin-left: -70px;
}
.Main-Footer ul li {
  list-style: none;
  display: inline;
  padding: 0 22px;
}
.Main-Footer ul li a {
  color: #484848;
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;
}
.Main-Footer ul li a:hover {
  color: #fc681c;
}

/* Responisve Style*/

@media screen and (max-width: 1086px) {
  .Main-Footer ul li {
    padding: 0 10px;
  }
}
@media screen and (max-width: 991px) {
  .Main-Footer ul li {
    padding: 0 10px;
  }
  .Main-Footer ul li a {
    font-size: 12px;
  }
}
@media screen and (max-width: 500px) {
  .Main-Footer ul li {
    list-style: none;
    padding: 4px 0px;
    display: list-item;
    text-align: left;
  }
  .Main-Footer ul li a {
    font-size: 15px;
  }
  .Main-Footer img {
    width: 40%;
  }
}

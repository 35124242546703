.Auth-module-bg {
  background-image: url(../assets/bg-1.png);
  background-size: cover;
  background-position: fixed;
  /* padding: 10px 200px; */
  background-repeat: no-repeat;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 100vh; */
}
.Centered{
  display: flex;
  justify-content: center;
  align-items: center;
}
.Auth-module-card.Signin {
  background-color: #fff;
  padding: 35px;
  border-radius: 20px;
  vertical-align: middle;
  /* width: 967px;
    height: 712px; */
}

.toggle-password {
  float: right;
  cursor: pointer;
  margin-right: 10px;
  margin-top: -30px;
  position: relative;
}

.toggle-password-slash {
  position: absolute;
  left: 5px;
  color: #707070;
  font-size: 12pt;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.Welcome-card {
  padding-left: 1rem;
} /* Changed for Cosmetic Issue */

.Welcome-card h2 {
  color: #484848;
  font-size: 28px;
  font-weight: 500;
  opacity: 0.8;
}
.h-20{
  height: 4%;
  margin-top: -2.25rem;
}
.Welcome-card img {
  width: 40%;
  padding: 35px 0 0 0;
}
.Welcome-card p {
  /* padding: 35px 50px 0 0; //Cosmetic issue fix */
  padding: 35px 20px 0 0;
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: justify;
  color: #484848;
  opacity: 0.8;
}
.Input-field::placeholder {
  font-size: 12px;
  text-align: right;
}
.Input-field {
  border: 1px solid rgba(112, 112, 112, 0.6);
  border-radius: 4px;
  width: 100%;
  height: 35px;
  padding: 0 8px;
  opacity: 0.4;
  outline: none !important;
}
.Input-label {
  color: #707070;
  padding: 12px 0 0px 0;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}
.Btn-submit {
  width: 100%;
  background-color: #1592cb;
  border: none;
  color: #fff;
  font-weight: 600; /* cosmetic change */
  border-radius: 20px;
  padding: 8px 0;
  margin: 10px 0 0 0;
  outline: none !important;
}

.Loader {
  width: 1.5rem;
  height: 1.5rem;
}
/* LOGIN PAGE STYLES*/
.Loginform-card {
  border-radius: 12px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  padding: 15px 55px;
}
.Auth-module-card.Signin {
  background-color: #fff;
  padding: 35px;
  border-radius: 20px;
  vertical-align: middle;
  width: 880px;
  /* height: 535px; */
}
.Loginform-card h2 {
  color: #1592cb;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  padding: 10px 0 0 0;
}
.Loginform-card h3 {
  padding: 0 0 2% 0;
  font-size: 24px;
  font-weight: 600;
}
.Loginform-card p {
  color: #484848;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
}
.Forgot-pass {
  color: #484848;
  font-size: 12px;
  padding: 7px 0 0 0;
  float: right;
}
.Back-login {
  float: right;
  padding: 20px 0;
}
.Create-acc {
  text-align: center;
  margin-top: 1%;
  color: #484848;
}
.Create-acc a {
  color: #1592cb;
  font-size: 14px;
  font-weight: 500;
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 20px 0 16px 0;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: solid 1px #484848;
  opacity: 0.5;
}
/* .separator::before {
  margin-right: 0.25em;
}
.separator::after {
  margin-left: 0.25em;
} */
.separator span {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.53;
  letter-spacing: normal;
  color: #484848;
  opacity: 0.77;
}
.Social-btns {
  text-align: center;
}
.Social-btns button {
  border: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px rgba(72, 72, 72, 0.25) solid;
}
.Social-btns img {
  width: 28%;
  padding: 0 10px 0 0px;
  object-fit: contain;
}
/* SIGNUP PAGE STYLES*/
.Signup-form-card {
  border-radius: 12px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  padding: 14px 50px;
}
.Auth-module-card.Signup {
  background-color: #fff;
  /* padding: 35px; Changed for Cosmetic Issue #157 */
  padding: 3px 0 3px 20px;
  border-radius: 20px;
  vertical-align: middle;
  width: 945px;
  height: 638px;
}
.Signup-form-card h2 {
  color: #1592cb;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  /* padding: 12px 0 0 0; Commented for Cosmetic Issue #157 */ 
}

.Signup-form-card h3 {
  padding: 0 0 2% 0;
  font-size: 24px;
  font-weight: 600;
}
.Signup-form-card .Input-label {
  color: #707070;
  padding: 10px 0 0px 0;
  margin: 0;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: 0.26px;
}
.Signup-form-card p {
  color: #484848;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  padding: 8px 0 0 0;
  margin: 0 !important;
}
.Terms-conditions {
  text-align: center;
  display:flex;
  align-items: center;
}
.Terms-conditions a {
  color: #1592cb;
  font-size: 12px;
  font-weight: normal;
}
.strength-meter {
  position: relative;
  height: 3px;
  background: #ddd;
  margin: 7px 0;
  border-radius: 2px;
}
.strength-meter:before,
.strength-meter:after {
  content: "";
  height: inherit;
  background: transparent;
  display: block;
  border-color: #fff;
  border-style: solid;
  border-width: 0 6px 0;
  position: absolute;
  width: calc(25% + 6px);
  z-index: 10;
}
.strength-meter:before {
  left: calc(25% - 3px);
}
.strength-meter:after {
  right: calc(25% - 3px);
}

.strength-meter-fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s;
}
.strength-meter-fill[data-strength="0"] {
  width: 25%;
  background: darkred;
}
.strength-meter-fill[data-strength="1"] {
  width: 50%;
  background: orangered;
}
.strength-meter-fill[data-strength="2"] {
  width: 75%;
  background: orange;
}
.strength-meter-fill[data-strength="3"] {
  width: 100%;
  background: green;
}
.Error-Div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Error-Div p {
  font-size: 14;
  color: #f44336;
  display: inline;
  text-align: right;
}
.Alert-img {
  display: flex;
  width: 12;
  height: 12;
  object-fit: contain;
  align-self: center;
  margin-bottom: 10;
  margin-left: 4;
}
/* EMAIL VERIFICATION PAGE STYLES*/
.Resend-card {
  padding: 26% 8%;
  border-radius: 12px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
}
.Auth-module-card.Resend-outer {
  background-color: #fff;
  padding: 35px;
  border-radius: 20px;
  width: 856px;
  height: 475px;
}
.Resend-outer .Welcome-card p {
  padding: 35px 37px 0 0;
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: justify;
  color: #484848;
  opacity: 0.8;
}
.Resend-card h2 {
  color: #1592cb;
  font-size: 26px;
  font-weight: 600;
  text-align: center;
  padding: 16px 0 0 0;
}
.Resend-card h3 {
  padding: 0 0 2% 0;
  font-size: 24px;
  font-weight: 600;
}
.Resend-card p {
  color: #484848;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  padding: 8px 0 0 0;
}
/* FORGOT PASSWORD PAGE STYLES*/
.Forgot-card {
  border-radius: 12px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  padding: 20% 8%;
}
.Auth-module-card.Forgot-card-outer {
  background-color: #fff;
  padding: 35px;
  border-radius: 20px;
  width: 856px;
  height: 500px;
}
.Forgot-card-outer .Welcome-card p {
  padding: 35px 37px 0 0;
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: justify;
  color: #484848;
  opacity: 0.8;
}
.Forgot-card h2 {
  color: #1592cb;
  font-size: 26px;
  font-weight: 600;
  text-align: center;
  padding: 16px 0 0 0;
}
.Forgot-card h3 {
  padding: 0 0 2% 0;
  font-size: 24px;
  font-weight: 600;
}
.Forgot-card p {
  color: #484848;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  padding: 8px 0 0 0;
}
.Forgot-card button {
  margin: 30px 0 0 0;
}
/* CHECK MAIL PAGE STYLE*/
.Check-mail-card {
  border-radius: 12px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  padding: 14% 8%;
  text-align: center;
}
.Auth-module-card.Check-mail-outer {
  background-color: #fff;
  padding: 35px;
  border-radius: 20px;
  width: 856px;
  height: 484px;
}
.Check-mail-outer .Welcome-card p {
  padding: 35px 37px 0 0;
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: justify;
  color: #484848;
  opacity: 0.8;
}
.Check-mail-card h2 {
  color: #1592cb;
  font-size: 26px;
  font-weight: 600;
  text-align: center;
  padding: 16px 0 0 0;
}
.Check-mail-card h3 {
  padding: 0 0 2% 0;
  font-size: 24px;
  font-weight: 600;
}
.Check-mail-card p {
  color: #484848;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  padding: 8px 0 0 0;
}
.Check-mail-card img {
  width: 80%;
}
.Check-mail-card p {
  padding: 16px 0 0 0;
}
/* USER PERSONA STYLE*/
.User-persona {
  min-height: auto;
  /* background-color: #f4f5f8; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.User-persona-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0 25px 0;
}
.User-persona-main h2 {
  padding: 0 0 12px 0;
  margin: 0;
  color: #484848;
  font-size: 34px;
  font-weight: 600;
}
.User-persona-main h3 {
  font-size: 16px;
  font-weight: normal;
  letter-spacing: normal;
  color: #484848;
  padding: 0 0 6px 0;
  margin: 0;
}
.Persona-column {
  margin: 0 35px;
}
.Persona-card {
  background-color: #fff;
  text-align: center;
  padding: 24px 6px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 200px;
  transition-duration: 0.5s;
  cursor: pointer;
}
.Persona-card:hover {
  border: 2px solid #fc671a;
  padding: 30px;
}
.Persona-card.active {
  border: 2px solid #fc671a;
}
.Persona-card.active i {
  position: absolute;
  right: 30px;
  top: -22px;
  font-size: 26px;
  color: #fc671a;
}
.Persona-card img {
  width: 70%;
}
.Persona-column p {
  padding: 22px 0 10px 0;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #484848;
}
/*for header*/
.Right-wrap ul {
  display: none;
}

.sign-in { /* Added for Cosmetic Issue #157 */
  padding: 1rem 0 0;
  text-align: center;
  font-size: 500;
  font-weight: 500;
}

.sign-in > a {
  color: #1592cb;
}

/* Responisve Style*/
/* @media screen and (max-width: 1364px) {
    .Auth-module-card {
        width: 100%;
        height: auto;
    }
    .Auth-module-card.Signin{
        width: 100%;
        height: auto;
    }
} */
@media screen and (max-width: 991px) {
  .Persona-card {
    height: auto;
  }
  .Auth-module-bg {
    padding: 30px 30px;
    min-height: auto;
  }
  .Welcome-card h2 {
    display: none;
  }
  .Welcome-card p {
    display: none;
  }
  .Welcome-card img {
    padding: 0px 0 10px 0;
}
  .Auth-module-card.Signin {
    width: auto;
    height: auto;
    margin: 0 10px;
    padding: 0 10px;
  }
  .Auth-module-card.Signup {
    width: auto;
    height: auto;
    margin: 0 10px;
    padding: 0 10px;
  }
  .Auth-module-card.Forgot-card-outer {
    width: auto;
    height: auto;
    margin: 0 10px;
    padding: 0 10px;
  }
  .Auth-module-card.Check-mail-outer {
    width: auto;
    height: auto;
    margin: 0 10px;
    padding: 0 10px;
  }
  .Auth-module-card.Resend-outer {
    width: auto;
    height: auto;
    margin: 0 10px;
    padding: 0 10px;
  }
  .Loginform-card{
    padding: 14px 16px;
  }
  .Signup-form-card{
    padding: 14px 16px;
  }
  .Auth-module-card {
    text-align: center;
  }
  .Welcome-card p {
    padding: 35px 0 20px 0;
    text-align: center;
  }
  .Forgot-card-outer .Welcome-card p {
    padding: 35px 0 20px 0;
    text-align: center;
  }
  .Check-mail-outer .Welcome-card p {
    padding: 35px 0 20px 0;
    text-align: center;
  }
  .Resend-outer .Welcome-card p {
    padding: 35px 0 20px 0;
    text-align: center;
  }
  .User-persona-main {
    padding: 10px 15px 25px 15px;
    text-align: center;
}
}
@media screen and (max-width: 500px) {
  .Auth-module-bg {
    padding: 30px 0px;
  }
  .Auth-module-card {
    padding: 35px 10px;
  }
}
